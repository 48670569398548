<template>
  <div>
    <!-- <div class="title-panel">Staff Info</div> -->
    <b-container class="no-gutters bg-white py-3">
      <b-row class="align-items-center justify-content-between">
        <b-col lg="5" md="6" sm="6" xs="10" cols="10">
          <b-input-group>
            <b-form-input

              class="search-bar"
              placeholder="Search ID, Staff Name"
              @keyup.enter="handleSearch($event)"
              v-model="filter.search"

            >
            </b-form-input>
            <b-input-group-append is-text class="mr-2">
              <b-iconstack font-scale="2" type="submit" @click="handleSearch()">
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-iconstack>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="text-right" lg="4" md="3" sm="6" xs="2" cols="2">
          <button
            type="button"
            class="btn button btn-mobile"
            @click="modalShow()"
          >
            <font-awesome-icon icon="plus" class="text-black d-sm-none" />
            <span class="d-none d-sm-block">
              <font-awesome-icon icon="plus-square" class="pointer" />
              Add Staff
            </span>
          </button></b-col
        >
        <b-col cols="12" class="mt-3">
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="showStaff"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(first_name)="{ item }">
              {{ item.first_name }} {{ item.last_name }}
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
          <Pagination
            :pageOptions="pageOptions"
            :filter="filter"
            :rows="rows"
            @pagination="$emit('filterPage', filter)"
            @handleChangeTake="$emit('filterPage', filter)"
          />
        </b-col>
      </b-row>
    </b-container>
    <ModalStaff
      ref="ModalStaff"
      :sales_id="sales_id"
      @setStaff="setStaff"
      :selectStaffList="selectStaffList"
      :selectStaffID="selectStaffID"
    />
  </div>
</template>
<script>
import ModalStaff from "./ModalStaff";
import Pagination from "@/components/Pagination";
export default {
  components: { ModalStaff, Pagination },
  props: ["sales_id", "staffObj"],

  data() {
    return {
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      showStaff: [],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      rows: 0,
      isBusy: false,
      items: [],
      fields: [
        {
          key: "sales_no",
          label: "Staff ID",
          thClass: "upper",
        },
        {
          key: "first_name",
          label: "Staff Name",
        },
      ],
      selectStaffList: [],
      selectStaffID: [],
    };
  },
  watch: {
    staffObj(val) {
      this.selectStaffID = val.data.map((el) => el.id);
      this.selectStaffList = [...val.data];
      this.showStaff = [...val.data];
      this.rows = val.total;
      return val;
    },
  },
  mounted() {
    this.setStaffObj();
  },
  methods: {
    setStaffObj() {
      let val = this.staffObj;
      if (val.data) {
        this.selectStaffID = val.data.map((el) => el.id);
        this.selectStaffList = [...val.data];
        this.showStaff = [...val.data];
        this.rows = val.total;
      }
    },
    handleSearch(val) {
      this.$emit("filterPage", this.filter);
      // var keys = ["sales_no", "first_name", "last_name"];
      // var result = this.selectStaffList.filter(function (e) {
      //   return keys.some(function (a) {
      //     return e[a].toString().toLowerCase().includes(val.toLowerCase());
      //   });
      // });
      // this.showStaff = result.length == 0 && val == "" ? this.items : result;
    },
    modalShow() {
      this.$refs.ModalStaff.show();
    },
    setStaff(val) {
      this.selectStaffID = val.map((el) => el.id);
      this.selectStaffList = [...val];
      this.showStaff = [...val];
      this.rows = val.length;
      this.$emit("setStaffID", this.selectStaffID);
    },
  },
};
</script>
